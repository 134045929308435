import React, { useState } from 'react';
import Inicio from './components/Inicio';
import { useNavigate  } from 'react-router-dom';

const CreateUser = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [mensaje, setMensaje] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMensaje(''); // Reiniciar mensaje

    try {
      const response = await fetch('https://gianini.com.ar/api/index.php?action=register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
      });
      const data = await response.json();

      if (data.message === "Registro exitoso") {
        navigate('/email-verifica', { state: { username: username } });
      } else {
        setMensaje(data.message);
        console.error('Error de autenticación:', data.message);
      }
    } catch (error) {
      setMensaje('Hubo un error en la solicitud.');
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      <h2>Crear Usuario</h2>
      <form onSubmit={handleSubmit} autoComplete="off">
        <label>Email:</label>
        <input
          type="email"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <label>Contraseña:</label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />

        <button type="submit">Registrar</button>
      </form>
      <Inicio />
      {mensaje && <p>{mensaje}</p>}
    </div>
  );
};

export default CreateUser;
