// src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home';
import Aviso from './Aviso';
import Login from './Login';
import Nuevo from './NuevoUsuario';
import VerEmail from './EmailVerifica';

const App = () => {
    return (
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home/:rubro" element={<Home />} /> 
          <Route path="/aviso" element={<Aviso />} />
          <Route path="/login" element={<Login />} />
          <Route path="/nuevo" element={<Nuevo />} />
          <Route path="/email-verifica" element={<VerEmail />} />

          

      </Routes>
    );
};

export default App;
