import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { useNavigate  } from 'react-router-dom';

const RoundButton = () => {

  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = () => {
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/aviso');
    } else {
      navigate('/login');
    }
  };



  return (
    <div 
      onClick={handleClick} 
      style={{ 
        position: 'absolute', 
        top: '120px', 
        right: '20px', 
        textDecoration: 'none', 
        cursor: 'pointer' 
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{
        backgroundColor: 'transparent',
        border: '2px solid #1dde16',
        borderRadius: '30%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'background-color 0.3s',
      }}>
        <FontAwesomeIcon icon={faBullhorn} style={{ color: '#1dde16', fontSize: '15px' }} />
      </div>


      {isHovered && (
        <div style={{
          backgroundColor: 'white',
          color: 'black',
          textAlign: 'center',
          borderRadius: '5px',
          padding: '5px',
          position: 'absolute',
          bottom: '100%', // Mueve el tooltip encima
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1,
          opacity: 1,
          transition: 'opacity 0.3s',
        }}>
          Crear Aviso
        </div>
      )}







    </div>
  );
  
};

export default RoundButton;

