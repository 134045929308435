// https://gianini.com.ar/data/api_aviso.php
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FileUploadButton from './components/FileUploadButton'; 
import Inicio from './components/Inicio';

import './Aviso.css'; // Asegúrate de importar el CSS

const Aviso = () => {
    const [files, setFiles] = useState([]);
    const [previews, setPreviews] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [message, setMessage] = useState('');
    const [rubros, setRubros] = useState([]); 
    const [selectedRubro, setSelectedRubro] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
      const fetchRubros = async () => {
          try {
              const response = await fetch('https://gianini.com.ar/data/api_rubros.php');
              const result = await response.json();
              setRubros(result); // Asumimos que result es un array de rubros
          } catch (error) {
              console.error('Error fetching rubros:', error);
          }
      };
      fetchRubros();
    }, []);

    const handleFileChange = (event) => {
      const selectedFiles = Array.from(event.target.files);

      if (files.length + selectedFiles.length > 4) {
        alert('Solo puedes subir un máximo de 4 archivos.');
        return;
      }

      const newFiles = [];
      const newPreviews = selectedFiles.map(file => {
          const fileType = file.type;
          if (fileType.startsWith('video/') || fileType.startsWith('image/')) {
              newFiles.push(file);
              return URL.createObjectURL(file);
          } else {
              alert('Por favor, selecciona solo archivos de imagen o video.');
              return null;
          }
      }).filter(Boolean); // Filtrar valores nulos


      // Agregar nuevos archivos a los ya seleccionados
      setFiles(prevFiles => [...prevFiles, ...newFiles]);
   
      setPreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
  };
  

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (files.length === 0) return;
        
        setIsUploading(true);
        
        const token = localStorage.getItem('token');


        const formData = new FormData();
        files.forEach((file, index) => {
            //formData.append('files[]', file);
            const uniqueName = `image_${Date.now()}_${index}.jpg`;
            formData.append('files[]', new File([file], uniqueName, { type: file.type }));
        });
        formData.append('message', message);
        formData.append('idrubro', selectedRubro);
        formData.append('token', token);

        try {
            // const response = await fetch('https://gianini.com.ar/data/api_aviso.php', {
            //     method: 'POST',
            //     body: formData,
            // });


            const response = await fetch('https://gianini.com.ar/api/index.php?action=newaviso', {
                method: 'POST',
                body: formData,
              });


            const result = await response.text();
            console.log(result);
            alert(result);
            setPreviews([]);
            setFiles([]);
            setMessage('');
            navigate('/');
        } catch (error) {
            console.error('Error al subir los archivos:', error);
            alert('Error al subir los archivos.', error);
        } finally {
            setIsUploading(false);
        }
    };


    return (
        <div>
          <form onSubmit={handleSubmit}>
          <h3>Creando un Aviso</h3>



          <label htmlFor="rubro">Selecciona un Rubro:</label>
          <select 
              id="rubro" 
              value={selectedRubro} 
              onChange={(e) => setSelectedRubro(e.target.value)} 
              required
          >
              <option value="">Seleccione un rubro</option>
              {rubros.map((rubro) => (
                  <option key={rubro.idrubro} value={rubro.idrubro}>
                      {rubro.rubro} {/* Asumiendo que cada rubro tiene un id y un nombre */}
                  </option>
              ))}
          </select>




          <FileUploadButton handleFileChange={handleFileChange} />
          {/* <input 
            type="file" 
            accept="image/*" 
            onChange={handleFileChange} 
            multiple
          /> */}
          {/* <input 
            type="file" 
            accept="video/*" 
            onChange={handleFileChange} 
            capture="camcorder" 
          /> */}

          <h3>Mensaje</h3>
            <textarea 
              value={message}
              onChange={(e) => setMessage(e.target.value)} 
              rows="4" 
              placeholder="Escribe tu mensaje aquí..." 
              required
              style={{ width: '100%', resize: 'none' }} 
            >
          </textarea>

          <button type="submit" disabled={isUploading}>
              {isUploading ? 'Subiendo...' : 'Subir Archivos'}
          </button>
        </form>

        {isUploading && <p>Cargando, por favor espera...</p>}
        
        {previews.length > 0 && (
          <div className="preview-container">
            <h3>Vistas previas:</h3>
            <div className="preview-grid">
                {previews.map((preview, index) => (
                    preview.endsWith('.mp4') || preview.endsWith('.webm') ? (
                        <video key={index} controls>
                            <source src={preview} type="video/mp4" />
                            Tu navegador no soporta la etiqueta de video.
                        </video>
                    ) : (
                      
                        <img key={index} src={preview} alt={`Vista previa ${index}`} />
                    )
                ))}
            </div>
          </div>
        )}
        <Inicio />
      </div>


    );
};

export default Aviso;
