import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const EmailVerifica = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(60);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const location = useLocation();
  const { username } = location.state || {};

  const checkEmailVerification = useCallback(async (username) => {
    try {
      const response = await fetch(`https://gianini.com.ar/api/index.php?action=checkVerification&userName=${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error al verificar el correo electrónico');
      }
      const data = await response.json();


      if (data.estado_us === 0) {
        console.log('Correo verificado');
        setVerificationStatus(true); // Actualiza el estado
        navigate('/login', { state: { email: username } });
      } else {
        console.log('El correo no ha sido verificado aún');
      }
    } catch (error) {
      console.error('Error:', error.message);
    }
  }, [navigate]); // Añadir navigate como dependencia

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0; // Detener el temporizador
        }
        return prev - 1;
      });

      if (verificationStatus !== true) {
        checkEmailVerification(username);
      }
    }, 5000);

    return () => clearInterval(interval); // Limpieza del intervalo
  }, [username, verificationStatus, checkEmailVerification]); // Añadir checkEmailVerification aquí

  return (
    <div>
      <h2>Verificación de Correo Electrónico</h2>
      {username && <p>Usuario: {username}</p>}
      <p>Por favor, revisa tu correo y haz clic en el enlace de verificación.</p>
      <p>Tiempo restante: {timer} segundos</p>
      {timer === 0 && <p>El tiempo de espera ha expirado. Por favor, intenta nuevamente.</p>}
    </div>
  );
};

export default EmailVerifica;
