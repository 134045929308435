import React, { useEffect, useState } from 'react';
import './BarraSuperior.css'; // Asegúrate de tener tu archivo CSS

const BarraSuperior = ({ onTagClick }) => {
    const [rubros, setRubros] = useState([]); // Estado para almacenar los rubros



    const [isDragging, setIsDragging] = useState(false); // Estado para arrastrar
    const [startX, setStartX] = useState(0); // Posición inicial
    const [scrollLeft, setScrollLeft] = useState(0); // Desplazamiento inicial

    useEffect(() => {
      const fetchRubros = async () => {
        try {
            const response = await fetch('https://gianini.com.ar/api/index.php?action=rubros', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (!response.ok) {
            throw new Error('Error al obtener los rubros');
            }

            const data = await response.json();
            console.log('Datos de los rubros:', data);

            setRubros(data);
        } catch (error) {
            console.error(error);
        }
      };
  
      fetchRubros();
    }, []);
  






  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseLeave = () => {
      setIsDragging(false);
  };

  const handleMouseUp = () => {
      setIsDragging(false);
  };

  const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault(); // Prevenir la selección de texto
      const x = e.pageX - e.currentTarget.offsetLeft;
      const walk = (x - startX) * 1; // Cambia el número para ajustar la velocidad
      e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
      setIsDragging(true);
      setStartX(e.touches[0].clientX - e.currentTarget.offsetLeft);
      setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleTouchMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.touches[0].clientX - e.currentTarget.offsetLeft;
      const walk = (x - startX) * 1;
      e.currentTarget.scrollLeft = scrollLeft - walk;
  };
  const handleTouchEnd = (e, rubro) => {
    if (isDragging) {
        setIsDragging(false);
    } else {
        onTagClick(rubro);
    }
  };







   return (
    <div className="home">
        <div
            className="tag-container"
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={(e) => {
                rubros.forEach(rubro => {
                    const target = e.target.closest('.tag');
                    if (target && target.innerText === rubro.rubro) {
                        handleTouchEnd(e, rubro);
                    }
                });
            }}
            style={{ overflowX: 'auto', whiteSpace: 'nowrap' }} // Asegúrate de tener estilo adecuado
        >
            {rubros.map((rubro) => (
                <div
                    key={rubro.idrubro}
                    className="tag"
                    onClick={() => !isDragging && onTagClick(rubro)} // Solo ejecutar onTagClick si no se está arrastrando
                    onTouchEnd={() => onTagClick(rubro)}
                    style={{
                        padding: '10px',
                        margin: '5px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        display: 'inline-block',
                    }}
                >
                    {rubro.rubro}
                </div>
            ))}
        </div>
    </div>
);



















};

export default BarraSuperior;
