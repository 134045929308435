import React from 'react';
import { useNavigate  } from 'react-router-dom';
import './Inicio.css';

const InicioButton = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };



  return (

    <button onClick={handleClick}  className="back-button">
        Volver al Inicio
    </button>
  );
 
};

export default InicioButton;


