import React from 'react';

const FileUploadButton = ({ handleFileChange }) => {
  return (
    <label htmlFor="file-upload" style={labelStyle}>
      <span className="material-icons" style={iconStyle}>photo_camera</span>
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        multiple
        style={{ display: 'none' }} // Ocultar el input
      />
    </label>
  );
};

// Estilos en línea para el botón y el icono
const labelStyle = {
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80px',  // Ajusta el tamaño según necesites
  height: '80px', // Ajusta el tamaño según necesites
  backgroundColor: '#f0f0f0',
  borderRadius: '50%', // Hacerlo circular
  transition: 'background-color 0.3s',
};

const iconStyle = {
  fontSize: '24px', // Tamaño del icono
};

export default FileUploadButton;
