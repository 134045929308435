import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Inicio from './components/Inicio';

import './Login.css';


const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Inicializa useNavigate


    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Reinicia el error
        // Aquí puedes agregar la lógica para autenticar al usuario


        try {
            // Llamada a la API de inicio de sesión
            const response = await fetch('https://gianini.com.ar/api/index.php?action=login', {
                method: 'POST', // Método de la solicitud
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Tipo de contenido
                },
                //body: JSON.stringify({ email, password }), // Datos a enviar
                body: `username=${email}&password=${password}`
            });

            // Verifica si la respuesta es exitosa
            if (!response.ok) {
                throw new Error('Error en el inicio de sesión');
            }
            const data = await response.json();
            // Verifica si data.message existe
            if (data.token) {
                localStorage.setItem('token', data.token);
                navigate('/aviso'); // Redirige a /avisos
            } else {
                localStorage.removeItem('token');
                setError(data.message);
            }
        } catch (err) {
            setError('Ocurrió un error. Inténtalo de nuevo.');
        }
    };

    const handleCreateUser = () => {
        navigate('/nuevo');
    };


    
    return (
        <div className="container">
            <h2>Iniciar Sesión</h2>
            <form onSubmit={handleSubmit} autoComplete="off">
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <label>Contraseña:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                {error && <div className="error-message">{error}</div>}
                <button type="submit">Iniciar Sesión</button>
            </form>
            <button type="button" onClick={handleCreateUser}>
                Crear usuario
            </button>

            <Inicio />
        </div>
    );
};

export default Login;
