// src/Home.js
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams  } from 'react-router-dom'; 

import Rubros from './components/BarraSuperior';
import Avisos from './components/BotonAviso';
import Cierre from './components/BotonCierreSesion';

import './Home.css';




const Home = () => {
    const { rubro = '0'} = useParams(); 
    const [data, setData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentMediaIndex, setCurrentImageIndex] = useState(0);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const touchStartRef = useRef(null);
    const touchEndRef = useRef(null);
    const navigate = useNavigate ();
    const token = localStorage.getItem('token');


    useEffect(() => {
        const fetchData = async () => {
            console.log('Cargando datos para rubro:', rubro); 
            try {
                let url = 'https://gianini.com.ar/api/index.php?action=avisos';
                if (rubro !== 0) {
                    url += `&id=${encodeURIComponent(rubro)}`;
                }
                console.log(url);
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                const result = await response.json();
                console.log('Datos de los avisos:', result);
                setData(result);
                setCurrentIndex(0); // Reiniciar el índice al cambiar de rubro
                setCurrentImageIndex(0); // Reiniciar el índice de imagen al cambiar de rubro

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [rubro]);

    // Efecto para actualizar el ancho de la ventana
    useEffect(() => {
      const handleResize = () => {
          setWindowWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);






    const handleTouchStart = (e) => {
        touchStartRef.current = {
            clientX: e.touches[0].clientX,
            clientY: e.touches[0].clientY,
        };
    };

    const handleTouchEnd = (e) => {
        touchEndRef.current = {
            clientX: e.changedTouches[0].clientX,
            clientY: e.changedTouches[0].clientY,
        };

        const touchDiffX = touchStartRef.current.clientX - touchEndRef.current.clientX;
        const touchDiffY = touchStartRef.current.clientY - touchEndRef.current.clientY;

        // Verificamos si el deslizamiento fue más horizontal que vertical
        if (Math.abs(touchDiffX) > Math.abs(touchDiffY)) {
            if (touchDiffX > 30) {
                // Deslizamiento hacia la izquierda
                setCurrentImageIndex((prevIndex) => (prevIndex + 1) % mediaUrls.length);
            } else if (touchDiffX < -30) {
                // Deslizamiento hacia la derecha
                setCurrentImageIndex((prevIndex) => (prevIndex - 1 + mediaUrls.length) % mediaUrls.length);
            }
        } else {
            // Deslizamiento vertical para cambiar de registro
            if (touchDiffY > 30) {
                // Deslizamiento hacia arriba
                setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
                setCurrentImageIndex(0);
            } else if (touchDiffY < -30) {
                // Deslizamiento hacia abajo
                setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
                setCurrentImageIndex(0);
            }
        }
    };


    if (data.length === 0) return <div>Cargando...</div>;

    const { images, description } = data[currentIndex];

    console.log('Descripción:', description);
    console.log('Imágenes crudas:', images);

    let mediaUrls = [];

    try {
        // eslint-disable-next-line
        const cleanedImages = images.replace(/[\[\]"]/g, '').trim();
        mediaUrls = cleanedImages.split(',').map(url => url.trim()).filter(url => url);
    } catch (error) {
        console.error('Error parsing images:', error);
    }

    console.log('Imágenes procesadas:', mediaUrls);

    const handleTagClick = (rubro) => {
        console.log(`Has clicado en: ${rubro.idrubro}`);
        navigate(`/home/${rubro.idrubro}`);

    };

    return (
        <div
            className="full-screen"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        >

            <Rubros  onTagClick={handleTagClick} /> 
 
 
            {mediaUrls.length > 0 ? (
                mediaUrls[currentMediaIndex].endsWith('.mp4') ? (
                    //<video src={mediaUrls[currentMediaIndex]} className="media" controls autoPlay loop />
                    <video src={mediaUrls[currentMediaIndex]} className="media" controls autoPlay/>
                ) : (
                    <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
                    <img src={mediaUrls[currentMediaIndex]} alt={description} className="image" />
                    </div>
                )
                
            ) : (
                <div>No hay imagen o video disponible</div>
            )}



            <div className="carousel-indicators">
                {mediaUrls.map((_, index) => (
                <span
                    key={index}
                    className={`indicator ${index === currentMediaIndex ? 'active' : ''}`}
                    onClick={() => setCurrentImageIndex(index)}
                    style={{
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: index === currentMediaIndex ? 'black' : 'lightgray',
                    margin: '0 5px',
                    cursor: 'pointer',
                    }}
                />
                ))}
            </div>






            <div className="description">
                {description}
            </div>

            {windowWidth > 768 && (
            <div className="carousel-controls">
                <button 
                    onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex - 1 + mediaUrls.length) % mediaUrls.length)}>
                    ←
                </button>
                <button 
                    onClick={() => setCurrentImageIndex((prevIndex) => (prevIndex + 1) % mediaUrls.length)}>
                    →
                </button>
            
            </div>
            )}

            {windowWidth > 768 && (
            <div className="navigation-controls">
                <button onClick={() => setCurrentIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length)}>
                ↑
                </button>
                <button onClick={() => setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length)}>
                ↓
                </button>
            </div>
            )}

            { token && 
                <Cierre /> 
            }

            <Avisos />


        </div>
    );
};

export default Home;
