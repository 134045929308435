import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';



// todo esto es para que se instale solo

import reportWebVitals from './reportWebVitals';



let deferredPrompt;
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then((registration) => {
        console.log('Service Worker registrado con éxito:', registration);
      })
      .catch((error) => {
        console.error('Error al registrar el Service Worker:', error);
      });
  });
}
// Agregar el evento beforeinstallprompt
window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault(); // Evita que aparezca el banner predeterminado
  deferredPrompt = e; // Guarda el evento para usarlo más tarde

  const installButton = document.getElementById('installButton');
  installButton.style.display = 'block'; // Muestra el botón de instalación

  installButton.addEventListener('click', () => {
    installButton.style.display = 'none'; // Oculta el botón
    deferredPrompt.prompt(); // Muestra el cuadro de diálogo de instalación
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('El usuario aceptó la instalación');
      } else {
        console.log('El usuario rechazó la instalación');
      }
      deferredPrompt = null; // Resetea el prompt
    });
  });
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
